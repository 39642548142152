body {
  margin: 0;
  font-family: "Noto Serif Kannada", "Open Sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Noto Serif Kannada", "Open Sans", serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.underline {
  border-bottom-width: 2px;
}

.homepageBanner {
  @apply w-full h-60 md:h-96 bg-homepage-banner_two bg-no-repeat bg-center bg-contain relative mt-6;
}

.homepageBanner_title {
  @apply text-4xl text-red-900 font-semibold absolute w-full text-center top-1/2 left-1/2 p-2.5 -translate-x-1/2 -translate-y-1/2;
  text-shadow: 2px 2px 8px #ffffff;
}

.commonTile {
  @apply w-full h-auto text-left py-2.5;
}

.commonTile_header {
  @apply text-xl text-red-900 font-semibold;
}

.commonTile_description {
  @apply text-base text-slate-900 text-justify;
}

.events_image {
  @apply w-full h-96 object-fill;
}

.line-clamp {
  @apply overflow-hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notice {
  background-color: yellow;
  padding: 2px;
  color: black;
  font-weight: bold;
}

.ngyv_link {
  font-size: 16px;
  color: blue;
}
